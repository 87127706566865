import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "bootstrap/dist/css/bootstrap.css";
import 'video-react/dist/video-react.css'; // import css 
import "./index.css";
import App from "./App";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux";

import { GOOGLE_CLIENT_ID } from "./config/authConfig";
import { Web3ModalProvider } from "./provider/Web3ModalProvider";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<Web3ModalProvider>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}> 
					<GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}> 
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</GoogleOAuthProvider>
			</PersistGate>
		</Provider>
	</Web3ModalProvider>
);
